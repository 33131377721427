import { Center } from "@chakra-ui/react";
import React, { Suspense } from "react";
import { RingLoader } from "react-spinners";
import BranchAdminRoutes from "./routes/BranchAdminRoutes";
import NoAuthRoutes from "./routes/NoAuthRoutes";
import ParentRoutes from "./routes/ParentRoutes";
import SchoolAdminRoutes from "./routes/SchoolAdminRoutes";

import ScrollToTop from "./utilities/functions/ScrollToTop";
import { useAuth } from "./utilities/hooks/Auth";

function App() {
  const { type, token, user, checked, login, logout } = useAuth();

  let routes;
  // token && type === "SCHOOL"
  if (token && type === "SCHOOL") {
    routes = <SchoolAdminRoutes />;
    // token && type === "BRANCH"
  } else if (token && type === "BRANCH") {
    routes = <BranchAdminRoutes />;
  } else if (token && type === "PARENT") {
    routes = <ParentRoutes />;
  } else if (type === "NOAUTH") {
    routes = <NoAuthRoutes />;
  }

  return (
    <div>
      <ScrollToTop />
      <main>
        <Suspense
          fallback={
            <Center alignSelf={"center"} minH="500px">
              <RingLoader size={60} />
            </Center>
          }
        >
          {checked ? (
            routes
          ) : (
            <Center alignSelf={"center"} minH="500px">
              <RingLoader size={60} />
            </Center>
          )}
        </Suspense>
      </main>
    </div>
  );
}

export default App;
