import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const Branch = React.lazy(() => import("../branch/Branch"));
const ManageClass = React.lazy(() =>
  import("../branch/manageClass/ManageClass")
);
const BroadcastMessage = React.lazy(() =>
  import("../branch/broadcastMessage/BroadcastMessage")
);
const ManageStudents = React.lazy(() =>
  import("../branch/manageStudents/ManageStudents")
);

const Dashboard = React.lazy(() => import("../branch/dashboard/Dashboard"));

const ErrorPage = React.lazy(() => import("../utilities/pages/ErrorPage"));

const BranchAdminRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/Dashboard" />} />
        <Route path="/" element={<Branch />}>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="Manage_class" element={<ManageClass />} />
          <Route path="Manage_students" element={<ManageStudents />} />
          <Route path="Broadcast_message" element={<BroadcastMessage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default BranchAdminRoutes;
