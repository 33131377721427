import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const AdminLogin = React.lazy(() => import("../auth/login/AdminLogin"));
const ParentLogin = React.lazy(() => import("../auth/login/ParentLogin"));

const NoAuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<ParentLogin />} />
      <Route path="/admin-login" element={<AdminLogin />} />
      <Route path="*" element={<Navigate to="/admin-login" />} />
    </Routes>
  );
};

export default NoAuthRoutes;
