import React from "react";
import { Route, Routes } from "react-router-dom";
const ErrorPage = React.lazy(() => import("../utilities/pages/ErrorPage"));
const Parent = React.lazy(() => import("../parent/Parent"));

const ParentRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Parent />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default ParentRoutes;
