import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const ManageParents = React.lazy(() =>
  import("../school/manageParents/ManageParents")
);

const School = React.lazy(() => import("../school/School"));

const Calender = React.lazy(() => import("../school/calender/Calender"));
const Dashboard = React.lazy(() => import("../school/dashboard/Dashboard"));
const BroadcastMessage = React.lazy(() =>
  import("../school/broadcastMessage/BroadcastMessage")
);
const ManageBranch = React.lazy(() =>
  import("../school/manageBranch/ManageBranch")
);
const ManageSchool = React.lazy(() =>
  import("../school/manageSchool/ManageSchool")
);
const ManageStudents = React.lazy(() =>
  import("../school/manageStudents/ManageStudents")
);
const ErrorPage = React.lazy(() => import("../utilities/pages/ErrorPage"));

const SchoolAdminRoutes = () => {
  //Hook

  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/Dashboard" />} />
        <Route path="/" element={<School />}>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="Calender" element={<Calender />} />
          <Route path="Manage_branch" element={<ManageBranch />} />
          <Route path="Manage_students" element={<ManageStudents />} />{" "}
          <Route path="Manage_parents" element={<ManageParents />} />
          <Route path="Manage_school" element={<ManageSchool />} />
          <Route path="Broadcast_message" element={<BroadcastMessage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default SchoolAdminRoutes;
